import React from "react";
import styles from './SearchBox.module.scss';

const SearchBox = () => {
    return (
        <form action="https://www.google.com/search" className={styles['searchform']} method="get" name="searchform" target="_blank">
            <input name="sitesearch" type="hidden" value="www.aravindiyer.com" />
            <input autocomplete="off" className={styles['searchinput']} name="q" placeholder="Search Aravind's Blog" required="required"  type="text" />
            <button className={styles['searchbutton']} type="submit">Search</button>
        </form>
    );
};

export default SearchBox;