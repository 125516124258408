// @flow strict
import React from 'react';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import SearchBox from '../components/SearchBox';
import { useSiteMetadata } from '../hooks';

const NotFoundTemplate = () => {
  const { title, subtitle } = useSiteMetadata();

  return (
    <Layout title={`Not Found - ${title}`} description={subtitle}>
      <Sidebar />
      <Page title="Not Found">
        <p>Oops! You just hit a page that doesn&#39;t exist! Try searching for what you were looking for.</p>
        <SearchBox />
      </Page>
    </Layout>
  );
};

export default NotFoundTemplate;
