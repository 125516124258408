// @flow strict
import React from 'react';
import styles from './Credit.module.scss';

type Props = {
  credit: string
};

const Credit = ({ credit }: Props) => (
  <div className={styles['credit']} dangerouslySetInnerHTML={{ __html: credit }} />
);

export default Credit;
